import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Header as Title } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props extends PropsWithChildren {
  title?: ReactNode
  onClose?: () => void
  className?: string
}

const Header = ({ title, children, onClose, className }: Props) => {
  return (
    <div className={clsx(styles.header, className)}>
      <Flex alignItems="flex-start" justifyContent="space-between">
        {title && <Title variant="h2">{title}</Title>}
        {onClose && (
          <Icon
            name={IconName.close}
            onClick={onClose}
            size="md"
            className={styles.close}
          />
        )}
      </Flex>
      {children}
    </div>
  )
}

export { Header }
