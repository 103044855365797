import { requestWithoutToken } from '../request'

const getClient = async (subdomain: string) => {
  try {
    const response = await requestWithoutToken.get('/client/config', {
      params: { subdomain },
    })
    return response?.data?.client || null
  } catch {
    return null
  }
}

const getLogoUrl = async (subdomain: string) => {
  try {
    const response = await requestWithoutToken.get('/client/config', {
      params: { subdomain },
    })
    return response?.data?.client?.image?.url || null
  } catch {
    return null
  }
}

export { getClient, getLogoUrl }
