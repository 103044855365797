import { AxiosError } from 'axios'
import { debounce, isArray, isString } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import ReactSelect from 'react-select'
import { calculateFieldValue } from 'admin/services/api/loan-fields'
import DatePicker from 'components/DatePicker/DatePicker'
import { Option } from 'components/Form'
import { FormulaWrapper } from 'components/Formula/FormulaWrapper'
import { IconInput } from 'components/IconInput'
import { InputPhone } from 'components/InputPhone'
import { Field } from 'types/field'
import { formatField } from 'utils/fields'
import { formatPercent } from 'utils/math'

type Props = {
  singleLine?: boolean
  loanId?: string
  field: Field
  variables?: string[]
  onChange: ({ value, formula }: { value?: string; formula?: string }) => void
}

function InputField({ loanId, field, variables, onChange, singleLine }: Props) {
  const {
    type,
    property: { value, formula: initialFormula },
    options,
  } = field
  const [error, setError] = useState(undefined)
  const [formula, setFormula] = useState(
    isArray(initialFormula) ? initialFormula[0] : initialFormula
  )
  const indexValue = value[0]
  const indexType = type[0]

  const calculate = useMemo(
    () =>
      debounce(async (formula: string) => {
        try {
          const data = await calculateFieldValue({
            loanId: loanId as string,
            fieldId: field.id,
            formula: formula,
          })
          onChange({
            value:
              indexType === 'percentage'
                ? (formatPercent(data.value) as string)
                : data.value,
            formula,
          })
          setError(undefined)
        } catch (e) {
          onChange({ value: '', formula })
          setError((e as AxiosError<any>).response?.data || 'ERROR')
        }
      }, 500),
    [loanId, field.id]
  )

  const handleFormulaChange = useCallback(
    (formula: string) => {
      setFormula(formula)
      onChange({ value: indexValue as string, formula })
      calculate(formula)
    },
    [onChange, indexValue, calculate]
  )

  switch (indexType) {
    case 'option':
    case 'yes-no': {
      const selectOptions: Option[] =
        indexType === 'yes-no'
          ? [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]
          : (options as unknown as Option[])
      return (
        <ReactSelect
          classNamePrefix="formFieldSelect"
          placeholder="Select..."
          menuPortalTarget={document.getElementById('select-portal')}
          options={[
            { label: '(blank)', value: '' },
            ...((selectOptions as any[]) || []),
          ]}
          value={
            (selectOptions as any[])?.find(
              (option) => option.value === indexValue
            ) || {
              label: '(blank)',
              value: '',
            }
          }
          onChange={(option) => {
            const value = option && (isString(option) ? option : option.value)
            onChange({ value: value || '' })
          }}
        />
      )
    }
    case 'date':
      return (
        <DatePicker
          value={indexValue as string}
          onChange={(date) => onChange({ value: date })}
        />
      )
    case 'phone':
      return (
        <InputPhone
          value={indexValue || ''}
          onChange={(e) => onChange({ value: e.target.value })}
        />
      )
    case 'document':
      return <div />
    case 'percentage':
    case 'currency':
    case 'number':
    case 'decimal':
      return (
        <FormulaWrapper
          singleLine={singleLine}
          formula={formula as string}
          variables={variables}
          onFormulaChange={handleFormulaChange}
          formattedValue={
            indexValue
              ? formatField({
                  type: [indexType],
                  property: { value: [indexValue] },
                } as Field)
              : ''
          }
          error={error}
        >
          <IconInput
            type={indexType}
            value={indexValue || ''}
            onChange={(e) => onChange({ value: e.target.value })}
          />
        </FormulaWrapper>
      )
    default:
      return (
        <IconInput
          type={indexType}
          value={indexValue || ''}
          onChange={(e) => onChange({ value: e.target.value })}
        />
      )
  }
}

export { InputField }
