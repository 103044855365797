import { isUndefined, isEqual, findKey, last, uniqueId, omit } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  TableProductFields,
  Section,
} from 'admin/components/table/TableProductFields'
import {
  ModalField,
  FormValues as FieldFormValues,
} from 'admin/pages/Product/ModalField'
import { pathTo } from 'admin/path-to'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Steps } from 'components/Steps'
import { TextLink } from 'components/TextLink'
import { Product } from 'types'
import { Field } from 'types/field'
import { orderFields } from 'utils/fields'
import styles from './styles.module.scss'

export type PageKey =
  | 'loanTerms'
  | 'collateral'
  | 'documents'
  | 'loanSettings'
  | 'charges'

interface Props {
  title: string
  loanId?: string
  breadcrumbs: { link: string; title: string }
  editable?: boolean
  fields: Field[]
  settings: Product['settings']
  saving: boolean
  onPageChange: (page: PageKey) => void
  onSettingsChange: (setting: Product['settings']) => void
  onSave: (fields: Field[]) => void
  onCancel: () => void
}

const pageNames: Record<PageKey, string> = {
  loanTerms: 'General',
  charges: 'Charges',
  collateral: 'Collateral',
  documents: 'Documents',
  loanSettings: 'Loan Settings',
}

const pageOrder = [
  'loanTerms',
  'charges',
  'collateral',
  'documents',
  'loanSettings',
]

const getPageFields = (fields: Field[] = [], page: string): Section[] => {
  return fields.reduce((acc: Section[], field, index) => {
    if (field.page === page) {
      field.order = field.property.order || field.order || index
      const section = acc.find(({ section }) =>
        [field.section, field.sectionServicing].includes(section)
      )
      if (section) {
        section.fields.push(field)
      } else if (field.section || field.sectionServicing) {
        acc.push({
          section: (field.section || field.sectionServicing) as string,
          fields: [field],
        })
      }
    }
    return acc
  }, [])
}

function View({
  title,
  loanId,
  breadcrumbs,
  editable = false,
  fields: initialFields,
  settings,
  saving,
  onPageChange,
  onSettingsChange,
  onSave,
  onCancel,
}: Props) {
  const { id, tab: page = 'loanTerms' } = useParams() as {
    id: string
    tab: PageKey
  }
  const navigate = useNavigate()
  const [addingFieldSection, setAddingFieldSection] = useState<string>('')
  const [editingField, setEditingField] = useState<Field | null>(null)
  const [fields, _setFields] = useState<Field[]>(initialFields)
  const fieldsRef = useRef<Field[]>(fields)
  const setFields = useCallback(
    (nextFields: Field[]) => {
      fieldsRef.current = nextFields
      _setFields(nextFields)
    },
    [_setFields]
  )
  const pageFields = useMemo(
    () => getPageFields(orderFields(fields), pageNames[page]),
    [fields, page]
  )
  const variables = useMemo(
    () => fields.map((field) => field.name),
    [fields.length]
  )

  const handlePageClick = useCallback(
    (page: PageKey) => {
      onPageChange(page)
    },
    [onPageChange]
  )

  const handleSaveClick = useCallback(() => {
    onSave(
      fields.map(
        (field) =>
          ({
            ...field,
            id: field.id.includes('new-field-') ? undefined : field.id,
            property: {
              ...field.property,
              value: field.property.formula ? [] : field.property.value,
            },
          }) as Field
      )
    )
  }, [fields, onSave])

  const handleNextButton = useCallback(() => {
    const currentPageIndex = pageOrder.indexOf(page)
    const nextPage = pageOrder[currentPageIndex + 1]
    if (nextPage) {
      onPageChange(nextPage as PageKey)
      window.scrollTo(0, 0)
    } else {
      handleSaveClick()
    }
  }, [page, onPageChange, handleSaveClick])

  const handleBackButton = useCallback(() => {
    if (page === 'loanSettings') {
      onPageChange('documents')
    } else if (page === 'documents') {
      onPageChange('collateral')
    } else if (page === 'collateral') {
      onPageChange('charges')
    } else if (page === 'charges') {
      onPageChange('loanTerms')
    }
    window.scrollTo(0, 0)
  }, [page, onPageChange])

  const handleFieldChange = useCallback(
    (
      field: Field,
      {
        enabled,
        order,
        formula,
        value,
        ...fieldValues
      }: Partial<FieldFormValues> & { order?: number }
    ) => {
      setFields([
        ...fieldsRef.current.filter(({ id }) => id !== field.id),
        {
          ...field,
          ...fieldValues,
          property: {
            ...field.property,
            ...(isUndefined(order) ? {} : { order }),
            ...(isUndefined(formula) ? {} : { formula }),
            ...(isUndefined(value) ? {} : { value: [value] }),
            ...(isUndefined(enabled)
              ? {}
              : { enabled: enabled ? 'on' : 'off' }),
          },
        },
      ])
    },
    [setFields]
  )
  const handleDefaultChange = useCallback(
    (field: Field, data: { value?: string; formula?: string }) => {
      setFields([
        ...fieldsRef.current.filter(
          (currField) =>
            !isEqual(
              omit(currField, 'property.value', 'property.formula'),
              omit(field, 'property.value', 'property.formula')
            )
        ),
        {
          ...field,
          property: {
            ...field.property,
            formula: data.formula,
            value: [data.value],
          },
        },
      ])
    },
    [setFields]
  )
  const handleAddField = useCallback(
    (field: FieldFormValues) => {
      const sectionFields = fields.filter(
        ({ page: fieldPage, section }) =>
          fieldPage === pageNames[page] && section === addingFieldSection
      )
      const lastField = last(sectionFields)
      const maxOrder = lastField?.property.order ?? lastField?.order ?? 0

      setFields([
        ...fieldsRef.current,
        {
          id: uniqueId('new-field-'),
          ...field,
          editable: true,
          page: pageNames[page],
          section: addingFieldSection,
          order: maxOrder ? maxOrder + 1 : 1,
          property: {
            order: maxOrder ? maxOrder + 1 : 1,
            enabled: field.enabled ? 'on' : 'off',
            formula: field.formula ?? '',
            value: field.value ? [field.value] : [''],
          },
        } as unknown as Field,
      ])
      setAddingFieldSection('')
    },
    [page, addingFieldSection, fields, setFields, setAddingFieldSection]
  )
  const handleEditField = useCallback(
    (fieldValues: FieldFormValues) => {
      if (editingField) {
        handleFieldChange(editingField, fieldValues)
        setEditingField(null)
      }
    },
    [editingField, handleFieldChange, setEditingField]
  )
  const handleDeleteField = useCallback(
    (field: Field) => {
      setFields(
        fieldsRef.current.filter((currField) => !isEqual(currField, field))
      )
    },
    [setFields]
  )
  const handleReorder = useCallback(
    (tableFields: Field[]) => {
      setFields(
        orderFields(
          fieldsRef.current.map((field) => {
            const tableField = tableFields.find(({ id }) => id === field.id)
            const nextOrder = tableField?.order || field.order
            return {
              ...field,
              order: nextOrder,
              property: {
                ...field.property,
                order: nextOrder,
              },
            }
          })
        )
      )
    },
    [setFields]
  )

  return (
    <>
      <Flex stack gap={24}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={styles.header}
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Steps
            steps={Object.values(pageNames)}
            step={pageNames[page]}
            onClick={(page) =>
              handlePageClick(
                findKey(pageNames, (value) => value === page) as PageKey
              )
            }
          />
          <Button variant="tertiary" loading={saving} onClick={handleSaveClick}>
            Save & Exit
          </Button>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Header variant="h1">{title}</Header>
            {editable && (
              <Button
                size="small"
                onClick={() => navigate(pathTo('editProduct', id))}
              >
                Edit
              </Button>
            )}
          </Flex>
        </Flex>
        <TableProductFields
          loanId={loanId}
          data={pageFields}
          variables={variables}
          settings={settings}
          onReorder={handleReorder}
          onChange={handleFieldChange}
          onDefaultChange={handleDefaultChange}
          onSettingsChange={onSettingsChange}
          onAddField={setAddingFieldSection}
          onEditField={setEditingField}
          onDeleteField={handleDeleteField}
          page={page}
        />
      </Flex>
      <div className={styles.controls}>
        <TextLink variant="invert" onClick={onCancel}>
          Cancel
        </TextLink>
        <div className={styles.buttons}>
          {page !== 'loanTerms' && (
            <Button variant="tertiary" onClick={handleBackButton}>
              Back
            </Button>
          )}
          <Button loading={saving} onClick={handleNextButton}>
            {page === 'loanSettings' ? 'Save' : 'Next'}
          </Button>
        </div>
      </div>
      {(addingFieldSection || editingField) && (
        <ModalField
          loanId={loanId}
          existingFields={fields}
          field={editingField}
          onSubmit={editingField ? handleEditField : handleAddField}
          onCancel={() => {
            setEditingField(null)
            setAddingFieldSection('')
          }}
          page={page}
          section={addingFieldSection}
        />
      )}
    </>
  )
}

export { View }
