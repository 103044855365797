import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useEditFields,
  useEditProduct,
  useProduct,
} from 'admin/hooks/use-products'
import { pathTo } from 'admin/path-to'
import { PageLoader } from 'components/LoaderOverlay'
import { Product as IProduct } from 'types'
import { View } from './View'

function Product() {
  const navigate = useNavigate()
  const { id } = useParams() as { id: string }
  const { data } = useProduct({ id })
  const { mutate: saveProduct, isPending: savingProduct } = useEditProduct(id)
  const { mutate: saveFields, isPending: savingFields } = useEditFields()
  const [settings, setSettings] = useState({
    showBudget: true,
    showDrawRequests: true,
  })
  const handleSettingsChange = useCallback((setting: IProduct['settings']) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      ...setting,
    }))
  }, [])

  useEffect(() => {
    if (data) {
      setSettings({
        showBudget: data.product.settings?.showBudget ?? true,
        showDrawRequests: data.product.settings?.showDrawRequests ?? true,
      })
    }
  }, [data])

  return (
    <MainContent>
      {data ? (
        <View
          title={data.product.name}
          breadcrumbs={{ title: 'Products', link: pathTo('products') }}
          fields={data.fields}
          settings={settings}
          saving={savingProduct || savingFields}
          editable
          onPageChange={(page) => navigate(pathTo('product', id, page))}
          onSettingsChange={handleSettingsChange}
          onSave={(fields) => {
            saveProduct({ settings })
            saveFields(
              { id, fields },
              {
                onSuccess: () => {
                  navigate(pathTo('products'))
                },
              }
            )
          }}
          onCancel={() => navigate(pathTo('products'))}
        />
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Product }
