import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KEY_BORROWERS, KEY_INVESTORS, KEY_VENDORS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'
import {
  addEmail,
  deleteEmail,
  updateEmail,
  verifyEmail,
} from '../services/api/email'

const getKeyByPersonType = (personType: 'borrower' | 'investor' | 'vendor') => {
  let key: string = ''

  if (personType === 'borrower') {
    key = KEY_BORROWERS
  } else if (personType === 'investor') {
    key = KEY_INVESTORS
  } else if (personType === 'vendor') {
    key = KEY_VENDORS
  }

  return key
}

const useAddEmailMutation = (
  personType: 'borrower' | 'investor' | 'vendor',
  personId: string
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      email,
      sendVerification,
    }: {
      email: string
      sendVerification: boolean
    }) => {
      const person = await addEmail({ id: personId, email, personType })
      const createdEmail = person.emails?.find((e) => e.email === email)
      if (createdEmail && sendVerification) {
        await verifyEmail({
          id: personId,
          emailId: createdEmail.id,
          personType,
        })
      }
      return person
    },
    onSuccess: (person) => {
      queryClient.invalidateQueries({
        queryKey: [getKeyByPersonType(personType), personId],
      })
      queryClient.setQueryData(
        [getKeyByPersonType(personType), personId],
        person
      )
      message.success('Email saved')
    },
    onError: handleErrorResponse,
  })
}

const useSendEmailVerificationMutation = (
  personType: 'borrower' | 'investor' | 'vendor'
) => {
  return useMutation({
    mutationFn: ({ id, emailId }: { id: string; emailId: string }) =>
      verifyEmail({ id, emailId, personType }),
    onSuccess: () => {
      message.success('Email sent')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateEmailMutation = (
  personType: 'borrower' | 'investor' | 'vendor',
  personId: string
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      id,
      emailId,
      primary,
    }: {
      id: string
      emailId: string
      primary: boolean
    }) => updateEmail({ id, emailId, primary, personType }),
    onSuccess: (person) => {
      queryClient.invalidateQueries({
        queryKey: [getKeyByPersonType(personType), personId],
      })
      queryClient.setQueryData(
        [getKeyByPersonType(personType), personId],
        person
      )
      message.success('Email saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteEmailMutation = (
  personType: 'borrower' | 'investor' | 'vendor',
  personId: string
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, emailId }: { id: string; emailId: string }) =>
      deleteEmail({ id, emailId, personType }),
    onSuccess: (person) => {
      queryClient.invalidateQueries({
        queryKey: [getKeyByPersonType(personType), personId],
      })
      queryClient.setQueryData(
        [getKeyByPersonType(personType), personId],
        person
      )
      message.success('Email deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useAddEmailMutation,
  useSendEmailVerificationMutation,
  useUpdateEmailMutation,
  useDeleteEmailMutation,
}
