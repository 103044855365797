import clsx from 'clsx'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { QuoteOption as QuoteOptionType } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  option: QuoteOptionType
  selected?: boolean
  resetting: boolean
  onClick?: (option: QuoteOptionType) => void
  onEdit?: (option: QuoteOptionType) => void
  onDownload?: (option: QuoteOptionType) => void
  onUnselect?: (option: QuoteOptionType) => void
  onReset: (option: QuoteOptionType) => void
}

const QuoteOption = ({
  option,
  selected,
  resetting,
  onEdit,
  onDownload,
  onUnselect,
  onClick,
  onReset,
}: Props) => {
  const rate = sumDecimal([option.rate, option.compensationYieldSpread])
  const points = sumDecimal([option.points, option.compensationOriginationFee])
  const charges = sumDecimal([option.charges, option.compensationCharges])

  return (
    <Panel
      onClick={() => onClick && onClick(option)}
      className={clsx(
        styles.option,
        selected && styles.selected,
        onClick && styles.clickable
      )}
    >
      <Flex stack gap={16}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex gap={8} alignItems="center">
            {option.product.client.image?.url && (
              <img
                src={option.product.client.image.url}
                className={styles.optionLogo}
              />
            )}
            <Header variant="h4">{option.product.client.name}</Header>
            <div className={styles.divider}>|</div>
            <div>{option.product.name}</div>
          </Flex>
          <div className={styles.actions}>
            <Flex alignItems="center">
              {option.isDirty && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    onReset(option)
                  }}
                  size="small"
                  variant="tertiary"
                  loading={resetting}
                >
                  Reset
                </Button>
              )}
              <div className={styles.ellipsesActions}>
                <EllipsesActions>
                  {onEdit ? (
                    <EllipsesActions.Item icon onSelect={() => onEdit(option)}>
                      <Icon name={IconName.edit} />
                      Edit
                    </EllipsesActions.Item>
                  ) : (
                    <></>
                  )}
                  {onDownload ? (
                    <EllipsesActions.Item
                      icon
                      onSelect={() => onDownload(option)}
                    >
                      <Icon name={IconName.download} />
                      Download Sizer
                    </EllipsesActions.Item>
                  ) : (
                    <></>
                  )}
                  {onUnselect ? (
                    <EllipsesActions.Item
                      icon
                      onSelect={() => onUnselect(option)}
                      className="text-red-100"
                    >
                      <Icon name={IconName.delete} />
                      Remove
                    </EllipsesActions.Item>
                  ) : (
                    <></>
                  )}
                </EllipsesActions>
              </div>
            </Flex>
          </div>
        </Flex>
        <Grid>
          <Grid.Item xs={2}>
            <Summary name="Loan Amount">
              <Header variant="h3">{formatUsd(option.amount)}</Header>
            </Summary>
          </Grid.Item>
          <Grid.Item xs={2}>
            <Summary name="Est. Payment">
              <Header variant="h3">{formatUsd(option.payment)}</Header>
            </Summary>
          </Grid.Item>
          <Grid.Item xs={2}>
            <Summary name="Interest Rate">
              <Flex stack gap={4}>
                <Header variant="h3">{formatPercent(rate)}</Header>
                {!!option.compensationYieldSpread && (
                  <div className={styles.optionSummaryDetails}>
                    {formatPercent(option.rate, { showZero: true })}{' '}
                    <span className={styles.green}>
                      + {formatPercent(option.compensationYieldSpread)}
                    </span>
                  </div>
                )}
              </Flex>
            </Summary>
          </Grid.Item>
          <Grid.Item xs={2}>
            <Summary name="Points">
              <Flex stack gap={4}>
                <Header variant="h3">{formatPercent(points)}</Header>
                {!!option.compensationOriginationFee && (
                  <div className={styles.optionSummaryDetails}>
                    {formatPercent(option.points, { showZero: true })}{' '}
                    <span className={styles.green}>
                      + {formatPercent(option.compensationOriginationFee)}
                    </span>
                  </div>
                )}
              </Flex>
            </Summary>
          </Grid.Item>
          <Grid.Item xs={2}>
            <Summary name="Charges">
              <Flex stack gap={4}>
                <Header variant="h3">{formatUsd(charges)}</Header>
                {!!option.compensationCharges && (
                  <div className={styles.optionSummaryDetails}>
                    {formatUsd(option.charges, { showZero: true })}{' '}
                    <span className={styles.green}>
                      + {formatUsd(option.compensationCharges)}
                    </span>
                  </div>
                )}
              </Flex>
            </Summary>
          </Grid.Item>
          <Grid.Item xs={2}>
            <Summary name="Term">
              <Header variant="h3">
                {option.term ? `${option.term} months` : '-'}
              </Header>
            </Summary>
          </Grid.Item>
        </Grid>
      </Flex>
    </Panel>
  )
}

export { QuoteOption }
