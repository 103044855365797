import { useState } from 'react'
import { TopMenu } from 'admin/components/TopMenu'
import {
  useAddLoanModificationDocument,
  useDeleteLoanModificationDocument,
  useUpdateLoanModification,
} from 'admin/hooks/use-loan-modification'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { ModalGenerateDocument } from 'admin/pages/Loan/ModalGenerateDocument'
import { Button } from 'components/Button'
import { Drawer } from 'components/Drawer'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Loan, LoanModification as ILoanModification } from 'types'
import { LoanModification } from './LoanModification'

interface Props {
  loan: Loan
  isLoading: boolean
  isAddingModification: boolean
  modifications?: ILoanModification[]
  onEditModification: (id: string) => void
  onAddModification: () => void
  onClose: () => void
}

const DrawerModifications = ({
  loan,
  isLoading,
  isAddingModification,
  modifications,
  onAddModification,
  onEditModification,
  onClose,
}: Props) => {
  const [requestedDocumentModificationId, setRequestedDocumentModificationId] =
    useState<string>()
  const { mutate: updateModification, isPending: isUpdating } =
    useUpdateLoanModification({
      loanId: loan.id,
    })
  const { mutate: generateDocument, isPending: isGeneratingDocument } =
    useAddLoanModificationDocument(loan.id)
  const { mutate: deleteDocument } = useDeleteLoanModificationDocument(loan.id)
  const { openComments, openTasks, handleOwnersChange, comments } =
    useLoanContext()

  return (
    <>
      <Drawer loading={isLoading} onClose={onClose} className="px-4">
        <Drawer.Header className="pl-2.5 pr-0">
          <Flex justifyContent="space-between" alignItems="center">
            <Button variant="ghost" onClick={onClose} className="w-7 h-7">
              <Icon
                name={IconName.doubleArrowRight}
                className="text-black-100"
              />
            </Button>
            <TopMenu
              owners={loan.owners}
              onOwnersChange={handleOwnersChange}
              onTasksClick={openTasks}
              onHistoryClick={onClose}
              messagesCount={comments?.total}
              onCommentsClick={openComments}
            />
          </Flex>
        </Drawer.Header>
        <Drawer.Content className="w-auto mt-4 pt-0 pl-0 pr-4 mr-[-14px]">
          <Flex alignItems="center" justifyContent="space-between">
            <Header variant="h3">Loan Timeline</Header>
            <Button onClick={onAddModification} loading={isAddingModification}>
              New Loan Modification
            </Button>
          </Flex>
          <div className="mt-3 text-grey-900">
            {modifications?.map((modification, index) => (
              <LoanModification
                key={modification.id}
                loanId={loan.id}
                previousModification={modifications[index + 1]}
                modification={modification}
                onEditModification={onEditModification}
                onGenerateDocument={() =>
                  setRequestedDocumentModificationId(modification.id)
                }
                onDeleteDocument={deleteDocument}
              />
            ))}
          </div>
        </Drawer.Content>
      </Drawer>
      {requestedDocumentModificationId && (
        <ModalGenerateDocument
          templateId={
            modifications?.find(
              ({ id }) => id === requestedDocumentModificationId
            )?.templateId
          }
          saving={isGeneratingDocument || isUpdating}
          onGenerate={(templateId) => {
            updateModification(
              {
                id: requestedDocumentModificationId,
                templateId,
              } as ILoanModification,
              {
                onSuccess: () => {
                  generateDocument(requestedDocumentModificationId, {
                    onSuccess: () => {
                      setRequestedDocumentModificationId(undefined)
                    },
                  })
                },
              }
            )
          }}
          onClose={() => {
            setRequestedDocumentModificationId(undefined)
          }}
        />
      )}
    </>
  )
}

export { DrawerModifications }
