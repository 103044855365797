import {
  autoUpdate,
  flip,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  FloatingPortal,
} from '@floating-ui/react'
import clsx from 'clsx'
import { parseISO, isAfter } from 'date-fns'
import DOMPurify from 'dompurify'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { useNotifications } from 'hooks/use-notifications'
import { formatRelativeDate } from 'utils/date'
import { Tooltip } from '../Tooltip'
import styles from './styles.module.scss'

interface Props {
  buttonClassName?: string
  iconClassName?: string
  portalClassName?: string
}

const NotificationsIcon = ({
  buttonClassName,
  iconClassName,
  portalClassName,
}: Props) => {
  const navigate = useNavigate()
  const [hasNewNotification, setHasNewNotification] = useState(false)
  const [key, setKey] = useState('')
  const [open, setOpen] = useState(false)
  const { data: notifications } = useNotifications(key)
  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [shift(), flip()],
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context),
  ])
  useEffect(() => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    setHasNewNotification(
      !!notifications?.some(({ createdAt }) =>
        isAfter(parseISO(createdAt), yesterday)
      )
    )
  }, [notifications])
  useEffect(() => {
    setKey(open ? Date.now().toString() : '')
  }, [open])

  return (
    <div
      {...getReferenceProps({
        ref: refs.setReference,
        onClick(event) {
          event.stopPropagation()
        },
      })}
    >
      <Tooltip content={open ? '' : 'Notification'}>
        <div
          className={clsx(
            'cursor-pointer',
            styles.notificationIcon,
            hasNewNotification && styles.hasNotifications,
            buttonClassName
          )}
        >
          <Icon
            name={IconName.notification}
            size="md"
            className={iconClassName}
          />
          {open && (
            <FloatingPortal>
              <div
                {...getFloatingProps({
                  ref: refs.setFloating,
                  onClick(event) {
                    event.stopPropagation()
                  },
                  style: {
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                  },
                })}
                className={clsx(styles.notifications, portalClassName)}
              >
                <Header className={styles.title}>Notifications</Header>
                <Flex stack className={styles.list}>
                  {!notifications?.length && (
                    <div className={styles.empty}>
                      You don&apos;t have new notifications
                    </div>
                  )}
                  {notifications?.map(({ id, by, path, html, createdAt }) => (
                    <Flex
                      key={id}
                      className={styles.notification}
                      onClick={() => navigate(path)}
                    >
                      <Tooltip content={by && by.name}>
                        <Avatar
                          id={(by && by.id) || 'unknown'}
                          name={by && by.name}
                          tooltip={!!by}
                        />
                      </Tooltip>
                      <Flex stack gap={8}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(html),
                          }}
                        />
                        <div className={styles.date}>
                          {capitalize(formatRelativeDate(createdAt))}
                        </div>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </div>
            </FloatingPortal>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

export { NotificationsIcon }
