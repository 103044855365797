import clsx from 'clsx'
import { DragEvent, useCallback, useState } from 'react'
import { useClientImage, useAddClientImage } from 'admin/hooks/use-client-image'
import { DragDropFile } from 'components/DragDropFile'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Summary } from 'components/Summary'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { useSession } from 'hooks/use-session'
import { openBrowseFile } from 'utils/file'
import styles from './styles.module.scss'

function ClientImage() {
  const { user } = useSession()

  const [isDragActive, setIsDragActive] = useState(false)
  const { data: image } = useClientImage(user?.client.id as string)
  const { mutate: upload } = useAddClientImage(
    user?.client.id as string,
    image?.uploadUrl as string
  )
  const imageUrl = image?.image?.url || user?.client.image?.url
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => {
        upload(files)
      },
      accept: '.png,.svg',
    })
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    upload(files)
  }

  const DropZone = useCallback(
    () => (
      <DragDropFile
        onLeave={() => setIsDragActive(false)}
        onDrop={handleDrop}
        accept={['png', 'svg']}
      >
        <Flex stack gap={8} alignItems="center">
          <Icon
            name={IconName.uploadCloud}
            size="xl"
            className={styles.uploadIcon}
          />
          <Text>
            Drag and drop a PNG, or <TextLink>browse</TextLink>
          </Text>
        </Flex>
      </DragDropFile>
    ),
    [handleDrop]
  )

  return (
    <Grid gap={24}>
      <Grid.Item sm={12}>
        <Summary name="Logo">
          <Flex>
            <div
              className={styles.preview}
              onDragEnter={handleDrag}
              style={{ backgroundImage: `url(${imageUrl})` }}
              onClick={handleUpload}
            >
              {!imageUrl || isDragActive ? <DropZone /> : null}
            </div>
            {imageUrl && (
              <div
                className={clsx(styles.preview, 'bg-black-100')}
                style={{
                  backgroundImage: `url(${imageUrl})`,
                }}
                onDragEnter={handleDrag}
                onClick={handleUpload}
              >
                {isDragActive ? <DropZone /> : null}
              </div>
            )}
          </Flex>
        </Summary>
      </Grid.Item>
    </Grid>
  )
}

export { ClientImage }
