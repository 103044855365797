import { request } from 'services/request'
import { Field, Loan } from 'types'
import { updateResponseLoan, updateRequestFields } from 'utils/api/loan-parser'

const addLoanField = async (loanId: string, field: Field): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(
    `/loan/${loanId}/field`,
    updateRequestFields([field])[0]
  )
  return updateResponseLoan(loan)
}

const updateLoanField = async (loanId: string, field: Field): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(
    `/loan/${loanId}/field/${field.id}`,
    updateRequestFields([field])[0]
  )
  return updateResponseLoan(loan)
}

const removeLoanField = async (
  loanId: string,
  fieldId: string
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.delete(`/loan/${loanId}/field/${fieldId}`)
  return updateResponseLoan(loan)
}

const calculateFieldValue = async ({
  loanId,
  fieldId = 'formula',
  formula,
}: {
  loanId: string
  fieldId?: string
  formula: string
}): Promise<{ value: string }> => {
  const { data } = await request.post(`/loan/${loanId}/field/${fieldId}`, {
    formula,
  })
  return data
}

export { addLoanField, updateLoanField, removeLoanField, calculateFieldValue }
