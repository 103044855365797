import clsx from 'clsx'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { TopMenu } from 'admin/components/TopMenu'
import { useEditTask, useRemoveTask, useTasks } from 'admin/hooks/use-tasks'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { useTasksContext } from 'admin/pages/Tasks/TasksContext'
import { Button } from 'components/Button'
import { ButtonGroup } from 'components/ButtonGroup'
import { Checkbox } from 'components/Checkbox'
import { Drawer } from 'components/Drawer'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { OwnerSelector } from 'components/OwnerSelector'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { useSession } from 'hooks/use-session'
import { Task, Filter } from 'types'
import { friendlyDate } from 'utils/date'
import { EmptyView } from './EmptyView'
import { TemplateDropdown } from './TemplateDropdown'

type ITaskFilterName = 'Loan Tasks' | 'My Tasks' | 'All Tasks'

interface Props {
  onClose: () => void
}

function LoanTasksDrawer({ onClose }: Props) {
  const { user } = useSession()
  const navigate = useNavigate()
  const [openTemplateDropdown, setOpenTemplateDropdown] = useState(false)
  const [loanTasks, setLoanTasks] = useState<Task[]>([])
  const {
    loan,
    isOrigination,
    closeTasks,
    openTimeline,
    openTasks,
    openComments,
    handleOwnersChange,
    comments,
    tasksFilterName,
    setTasksFilterName,
  } = useLoanContext()
  const selectedFilter = useMemo((): Filter => {
    switch (tasksFilterName) {
      case 'Loan Tasks':
        return { loanId: [loan.id] }
      case 'My Tasks':
        return { ownerId: [user?.admin?.id || ''] }
      default:
        return {}
    }
  }, [tasksFilterName])
  const { data: tasks, isPending } = useTasks({
    filter: selectedFilter,
  })
  const { mutate: editTask } = useEditTask({ invalidateQueries: false })
  const { mutate: removeTask } = useRemoveTask()
  const { openTask } = useTasksContext()

  const handleRemoveTask = useCallback(
    (id: string) => {
      removeTask(id)
      setLoanTasks((prev) => prev.filter((task) => task.id !== id))
    },
    [removeTask]
  )
  const handleEditTask = useCallback(
    (id: string, nextTask: Partial<Task>) => {
      const task = loanTasks.find((task) => task.id === id)
      if (task) {
        editTask({
          ...task,
          owners: task.owners?.map(({ id }) => id) as unknown as Task['owners'],
          status: nextTask.status,
        })

        setLoanTasks((prev) =>
          prev.map((task) => (task.id === id ? { ...task, ...nextTask } : task))
        )
      }
    },
    [loanTasks, editTask]
  )

  useEffect(() => {
    setLoanTasks(tasks?.tasks || [])
  }, [tasks?.tasks])

  return (
    <>
      <Drawer
        loading={isPending}
        onClose={onClose}
        className="px-4 max-w-[640px]"
      >
        <Drawer.Header className="px-0">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" className="ml-2.5">
              <Button variant="ghost" onClick={onClose} className="w-7 h-7">
                <Icon
                  name={IconName.doubleArrowRight}
                  className="text-black-100"
                />
              </Button>
            </Flex>
            <TopMenu
              owners={loan.owners}
              onOwnersChange={handleOwnersChange}
              onTasksClick={closeTasks}
              onHistoryClick={isOrigination ? undefined : openTimeline}
              messagesCount={comments?.total}
              onCommentsClick={openComments}
            />
          </Flex>
        </Drawer.Header>
        <Drawer.Content className="w-auto mt-4 pt-2 pl-0 pr-4 mr-[-14px]">
          <Flex stack gap={32}>
            <div className="px-2">
              <Flex alignItems="center" justifyContent="space-between">
                <EllipsesActions
                  placement="bottom-start"
                  className="min-w-40"
                  trigger={
                    <Flex
                      gap={8}
                      alignItems="center"
                      className="cursor-pointer"
                    >
                      <Header variant="h3">{tasksFilterName}</Header>
                      <Icon
                        name={IconName.arrowDownFilled}
                        className="text-grey-600"
                      />
                    </Flex>
                  }
                >
                  <EllipsesActions.Item
                    onSelect={() => setTasksFilterName('Loan Tasks')}
                  >
                    Loan Tasks
                  </EllipsesActions.Item>
                  <EllipsesActions.Item
                    onSelect={() => setTasksFilterName('My Tasks')}
                  >
                    My Tasks
                  </EllipsesActions.Item>
                  <EllipsesActions.Item
                    onSelect={() => setTasksFilterName('All Tasks')}
                  >
                    All Tasks
                  </EllipsesActions.Item>
                </EllipsesActions>
                <ButtonGroup variant="primary">
                  <Button
                    onClick={() => {
                      closeTasks()
                      openTask(undefined, {
                        loanId: loan.id,
                        backButtonText: tasksFilterName,
                        onBack: () =>
                          openTasks({ filterName: tasksFilterName }),
                      })
                    }}
                  >
                    New Task
                  </Button>
                  <Tooltip
                    content={
                      openTemplateDropdown ? '' : 'Add tasks from template'
                    }
                    className="text-grey-900"
                  >
                    <TemplateDropdown
                      loanId={loan.id}
                      open={openTemplateDropdown}
                      onOpenChange={setOpenTemplateDropdown}
                    />
                  </Tooltip>
                </ButtonGroup>
              </Flex>
            </div>
            <Flex stack gap={0}>
              {loanTasks.length ? (
                loanTasks.map((task) => (
                  <Flex
                    key={task.id}
                    alignItems="center"
                    className="group cursor-pointer border-0 border-b last:border-b-0 border-grey-200 border-solid h-16"
                    onClick={() => {
                      closeTasks()
                      navigate(`#tasks-${task.id}`, { replace: true })
                      openTask(task.id, {
                        loanId: loan.id,
                        backButtonText: tasksFilterName,
                        onBack: () => {
                          navigate('#tasks', { replace: true })
                          openTasks({ filterName: tasksFilterName })
                        },
                      })
                    }}
                  >
                    <Checkbox
                      variant="rounded"
                      className={clsx(
                        task.status === 'Waived' &&
                          '!bg-grey-100 !border-grey-100'
                      )}
                      checked={task.status === 'Done'}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        handleEditTask(task.id, {
                          status: e.target.checked ? 'Done' : 'In Progress',
                        })
                      }}
                    />
                    <Flex stack gap={4} className="flex-grow w-32">
                      <Text className="font-bold truncate leading-snug">
                        {task.name || 'Untitled task'}{' '}
                        {task.subtasks.length > 0 && (
                          <span className="font-normal text-grey-700">
                            ({task.subtasks.filter(({ done }) => done).length}/
                            {task.subtasks.length})
                          </span>
                        )}
                      </Text>
                      {!!task.dateDue && (
                        <Flex
                          alignItems="center"
                          gap={6}
                          className="text-grey-700 truncate"
                        >
                          <Icon
                            name={IconName.calendar}
                            className="w-3.5 h-3.5"
                          />
                          {friendlyDate(task.dateDue)}
                        </Flex>
                      )}
                    </Flex>
                    <OwnerSelector
                      userOptions={
                        task.owners as {
                          id: string
                          name: string
                          email: string
                        }[]
                      }
                      selectedUsers={task.owners.map(({ id }) => id)}
                      readOnly
                    />
                    <div className="basis-8 flex-grow-0 flex-shrink-0">
                      <EllipsesActions triggerClassName="text-grey-600">
                        <EllipsesActions.Item
                          icon
                          onSelect={() => handleRemoveTask(task.id)}
                          className="text-red-100"
                        >
                          <Icon name={IconName.delete} />
                          Delete
                        </EllipsesActions.Item>
                      </EllipsesActions>
                    </div>

                    <Icon
                      name={IconName.arrowRight}
                      size="sm"
                      className="flex-shrink-0 invisible text-grey-600 group-hover:visible"
                    />
                  </Flex>
                ))
              ) : (
                <EmptyView />
              )}
            </Flex>
          </Flex>
        </Drawer.Content>
      </Drawer>
    </>
  )
}

export type { ITaskFilterName }
export { LoanTasksDrawer }
