import { compact, isNil } from 'lodash'
import { useCallback, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSaveDocumentSigners } from 'admin/hooks/use-document'
import { PersonOption } from 'admin/pages/Document/ModalSigners/PersonOption'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { useLoan } from 'hooks/use-loans'
import { Person, LoanAdmin } from 'types'
import { PersonSelect } from './PersonSelect'

interface Props {
  loanId?: string
  documentId: string
  signers: (Person | LoanAdmin)[]
  onDone: (signers: (Person | LoanAdmin)[]) => void
  onCancel: () => void
}

function ModalSigners({
  loanId,
  documentId,
  signers,
  onDone,
  onCancel,
}: Props) {
  const [selectedPersons, setSelectedPersons] = useState<
    (Person | LoanAdmin | undefined)[]
  >(signers?.length ? signers : [undefined])

  const { data: loan, isLoading: isLoanLoading } = useLoan({
    id: loanId,
  })
  const { mutate: saveSigners, isPending } = useSaveDocumentSigners({
    documentId: documentId,
  })

  const handleDrop = useCallback((dragIndex: number, dropIndex: number) => {
    if (!isNil(dragIndex) && !isNil(dropIndex)) {
      setSelectedPersons((ids) => {
        const personIds = [...ids]
        const dragPersonId = ids[dragIndex]
        personIds.splice(dragIndex, 1)
        personIds.splice(
          dragIndex < dropIndex ? dropIndex : dropIndex + 1,
          0,
          dragPersonId
        )
        return personIds
      })
    }
  }, [])

  const handleContinue = useCallback(() => {
    saveSigners(
      {
        signers: compact(selectedPersons).map((person) => ({ id: person.id })),
        loanId,
      },
      { onSuccess: () => onDone(compact(selectedPersons)) }
    )
  }, [onDone, selectedPersons])

  return (
    <Modal
      title="Signers"
      loading={isLoanLoading}
      onClose={onCancel}
      className="w-[564px]"
      focusFirstInput={false}
    >
      <Text className="text-grey-800 mb-6">
        Add people who need to sign the document below
      </Text>
      <Flex stack gap={12}>
        {selectedPersons.length === 1 && (
          <Flex stack gap={4}>
            <Text>Signer</Text>
            <PersonSelect
              loan={loan}
              person={selectedPersons[0]}
              onSelect={(personId) => setSelectedPersons([personId])}
            />
          </Flex>
        )}

        <DndProvider backend={HTML5Backend}>
          {selectedPersons.length > 1 &&
            selectedPersons.map((person, index) => (
              <PersonOption
                key={`person-selector-${index}`}
                index={index}
                loan={loan}
                person={person}
                onSelect={(person) => {
                  setSelectedPersons((persons) =>
                    persons.map((p, i) => (i === index ? person : p))
                  )
                }}
                onDrop={handleDrop}
                onDelete={() =>
                  setSelectedPersons((persons) =>
                    persons.filter((_, i) => i !== index)
                  )
                }
              />
            ))}
        </DndProvider>

        <div>
          <Button
            variant="ghost"
            onClick={() => setSelectedPersons([...selectedPersons, undefined])}
          >
            <Icon name={IconName.plusCircle} className="text-grey-600" />
            Add another signer
          </Button>
        </div>
      </Flex>

      <Flex justifyContent="flex-end" gap={12} className="mt-8">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={compact(selectedPersons).length === 0}
          onClick={handleContinue}
          loading={isPending}
        >
          Continue
        </Button>
      </Flex>
    </Modal>
  )
}

export { ModalSigners }
