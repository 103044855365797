import { PropsWithChildren, useEffect } from 'react'
import { useClient } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'

const AppCustomization = ({ children }: PropsWithChildren) => {
  const { user } = useSession()
  const { data } = useClient({
    enabled: !user,
  })
  const client = user?.client || data

  useEffect(() => {
    if (client?.name) {
      document.title = client.name
    }
  }, [client])
  return children
}

export { AppCustomization }
