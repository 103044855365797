// import { useMemo } from 'react'
// import { useParams } from 'react-router-dom'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
// import { TextLink } from 'components/TextLink'
// import { usePublicDocuments } from 'hooks/use-document'

interface Props {
  onClose: () => void
}

function ModalCompleteSigning({ onClose }: Props) {
  // const { authToken } = useParams()
  // if (!authToken) {
  //   return
  // }
  // const { data, isLoading } = usePublicDocuments(authToken)
  // const userId = useMemo(() => data?.person.id, [data])
  // const visibleDocuments = useMemo(
  //   () =>
  //     data?.documents.filter(({ esignature }) => !!esignature?.status) || [],
  //   [data]
  // )
  // const pendingDocuments = useMemo(
  //   () =>
  //     visibleDocuments.filter(
  //       (document) =>
  //         document.esignature?.currentSigner === userId &&
  //         !document.esignature?.currentSigned
  //     ) || [],
  //   [visibleDocuments, userId]
  // )
  // const pendingDocumentIds = useMemo(
  //   () => pendingDocuments.map(({ id }) => id),
  //   [pendingDocuments]
  // )
  // const hasNotSignedDocument = useMemo(
  //   () =>
  //     visibleDocuments.some((document) =>
  //       pendingDocumentIds.includes(document.id)
  //     ),
  //   [visibleDocuments, pendingDocumentIds]
  // )

  return (
    <Modal
      // loading={isLoading}
      onClose={onClose}
      className="w-modal m-0 pt-10 px-5 pb-6"
    >
      <Flex stack gap={0} alignItems="center" className="text-center">
        <Flex className="w-max p-4 rounded-[20px] bg-lime-25">
          <Icon
            name={IconName.approvedCircle}
            size="xl"
            className="w-[46px] h-[46px] text-lime-100"
          />
        </Flex>
        <div className="mt-8 text-4xl font-bold text-grey-900">
          Thank you for submitting your signature!
        </div>
        <div className="mt-3 mb-8 text-lg text-grey-800 leading-5">
          You will receive a final copy of the document once all parties have
          signed.
        </div>
      </Flex>

      {/* {hasNotSignedDocument && (
        <Flex
          stack
          className="pt-8 border-0 border-t-[1px] border-solid border-grey-200"
        >
          <div className="text-lg font-bold">Remaining signature requests</div>
          <Flex
            stack
            gap={0}
            className="w-full rounded border-solid border-[1px] border-grey-200"
          >
            {visibleDocuments.map((document) => (
              <Flex
                key={document.id}
                justifyContent="space-between"
                className="py-5 px-4 border-0 border-b last:border-b-0 border-grey-200 border-solid"
              >
                <Flex gap={12} alignItems="center">
                  <Icon
                    name={IconName.documentBlank}
                    className="text-grey-600"
                  />
                  <div className="text-black-100">{document.name}</div>
                </Flex>
                <Flex gap={12} alignItems="center" className="px-3.5 font-bold">
                  {pendingDocumentIds.includes(document.id) ? (
                    <TextLink
                      // onClick={() => setSignDocumentId(document.id)}
                      onClick={() =>
                        console.log('Start signing a new document.')
                      }
                      className="!text-blue-100"
                    >
                      Review & Sign
                      <Icon name={IconName.arrowRight} />
                    </TextLink>
                  ) : (
                    <div className="flex gap-2">
                      {['declined', 'recalled'].includes(document.status) ? (
                        <Icon
                          name={IconName.minusCircle}
                          size="lg"
                          className="text-red-100"
                        />
                      ) : (
                        <Icon
                          name={IconName.circleCheck}
                          size="lg"
                          className="text-lime-100"
                        />
                      )}
                      <div>
                        {document.esignature?.status === 'In Progress' &&
                        document.esignature?.currentSigned === true
                          ? 'Signed'
                          : document.esignature?.status || 'Signed'}
                      </div>
                    </div>
                  )}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )} */}
    </Modal>
  )
}

export { ModalCompleteSigning }
