import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLoanPayment } from 'admin/hooks/use-loan-payment'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Logo } from 'components/Logo'
import { useLoan } from 'hooks/use-loans'
import { formatAddress } from 'utils/address'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { getCalculations } from 'utils/payment'
import styles from './Receipt.module.scss'

function Receipt() {
  const { loanId, paymentId } = useParams() as {
    loanId: string
    paymentId: string
  }
  const { data: loan, isPending: isLoanLoading } = useLoan({ id: loanId })
  const { data: payment, isPending: isPaymentLoading } = useLoanPayment({
    loanId,
    paymentId,
    showDetails: false,
  })
  const [isLogoLoaded, setIsLogoLoaded] = useState<boolean>(false)
  const isLoaded = !isLoanLoading && !isPaymentLoading && isLogoLoaded

  const { sources, distributions } = useMemo(() => {
    const { sources, distributions } = getCalculations(payment, true)
    return {
      sources: sources.filter(({ name }) => name !== 'Borrower'),
      distributions,
    }
  }, [payment])

  return (
    payment && (
      <Flex
        data-all-loaded={isLoaded}
        className={styles.container}
        justifyContent="center"
      >
        <Flex stack gap={32} className={styles.contentWrapper}>
          <div className={styles.block}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex className={styles.headerWrapper} alignItems="center">
                <Header variant="h2">Payment Receipt</Header>
              </Flex>
              <Flex className={styles.logoWrapper} justifyContent="flex-end">
                <Logo
                  logoClassName={styles.logo}
                  to="/"
                  color="blue"
                  onLoad={() => setIsLogoLoaded(true)}
                />
              </Flex>
            </Flex>
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td>Address:</td>
                  <td>{formatAddress(loan?.addresses?.[0])}</td>
                </tr>
                <tr>
                  <td>Loan number:</td>
                  <td>{loan?.number}</td>
                </tr>
                <tr>
                  <td>Borrower:</td>
                  <td>{loan?.borrowers.map(({ name }) => name).join(', ')}</td>
                </tr>
                <tr>
                  <td>Method of payment:</td>
                  <td>{payment.type}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.block}>
            <Header className={styles.receivedIncome} variant="h2">
              {`${formatUsd(payment.source.borrower, {
                showZero: true,
              })} received on ${friendlyDate(payment.date)}`}
            </Header>
            <Flex
              className={styles.optionSection}
              justifyContent="space-between"
            >
              <div>Description</div>
              <div>Amount</div>
            </Flex>
            {distributions.map(({ name, value }) => (
              <Flex
                key={name}
                className={styles.optionSection}
                justifyContent="space-between"
              >
                <div>{name}</div>
                <div>{formatUsd(value)}</div>
              </Flex>
            ))}
            {sources.map(({ name, value }) => (
              <Flex
                key={name}
                className={styles.optionSection}
                justifyContent="space-between"
              >
                <div>{name}</div>
                <div>{formatUsd(-value)}</div>
              </Flex>
            ))}
            <Flex justifyContent="end">
              <Flex className={styles.amountReceived}>
                <div>Amount received</div>
                <div>
                  {formatUsd(payment.source.borrower, { showZero: true })}
                </div>
              </Flex>
            </Flex>
          </div>
        </Flex>
      </Flex>
    )
  )
}

export { Receipt }
