import { Link } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

function Footer() {
  const { user } = useSession()
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          className={styles.content}
        >
          <Flex flexWrap="wrap">
            <Flex stack gap={16} className={styles.block}>
              <Header variant="h4">Investor</Header>
              <Flex stack gap={8}>
                <Link to={pathTo('dashboard')} className={styles.link}>
                  Dashboard
                </Link>
                <Link to={pathTo('transactions')} className={styles.link}>
                  Transactions
                </Link>
                <Link to={pathTo('offerings')} className={styles.link}>
                  Offerings
                </Link>
                <Link to={pathTo('documents')} className={styles.link}>
                  Documents
                </Link>
              </Flex>
            </Flex>
          </Flex>
          <Logo to={pathTo('dashboard')} color="blue" />
        </Flex>
        <Flex stack gap={24} className={styles.disclaimer}>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Text variant="s">
              © {new Date().getFullYear()}{' '}
              {user?.client?.name || 'Baseline Financial Technologies Corp.'}
            </Text>
          </Flex>
        </Flex>
      </div>
    </div>
  )
}

export { Footer }
