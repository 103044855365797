import { Borrower } from 'admin/services/api/borrowers'
import { request } from 'services/request'

type PersonType = 'borrower' | 'investor' | 'vendor'

const addEmail = async ({
  id,
  email,
  personType,
}: {
  id: string
  email: string
  personType: PersonType
}): Promise<Borrower> => {
  const {
    data: { [personType]: person },
  } = await request.post(`/${personType}/${id}/email`, {
    email,
  })
  return person
}

const updateEmail = async ({
  id,
  emailId,
  primary,
  personType,
}: {
  id: string
  emailId: string
  primary: boolean
  personType: PersonType
}): Promise<Borrower> => {
  const {
    data: { [personType]: person },
  } = await request.patch(`/${personType}/${id}/email/${emailId}`, { primary })
  return person
}

const verifyEmail = ({
  id,
  emailId,
  personType,
}: {
  id: string
  emailId: string
  personType: PersonType
}) => {
  return request.post(`/${personType}/${id}/email/${emailId}/verify`)
}

const deleteEmail = async ({
  id,
  emailId,
  personType,
}: {
  id: string
  emailId: string
  personType: PersonType
}): Promise<Borrower> => {
  const {
    data: { [personType]: person },
  } = await request.delete(`/${personType}/${id}/email/${emailId}`)
  return person
}

export { addEmail, verifyEmail, updateEmail, deleteEmail }
