import clsx from 'clsx'
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import { Field, FieldType } from 'types'
import styles from '../Form/styles.module.scss'
import { InputCurrency } from '../InputCurrency'
import { InputNumber } from '../InputNumber'
import { InputPercentage } from '../InputPercentage'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  fieldClassName?: string
  fullAccuracy?: boolean
  type: FieldType | 'percentage-hover'
  mask?: Field['mask']
}

const IconInput = forwardRef(
  (
    { type, fieldClassName, mask, fullAccuracy, ...rest }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    switch (type) {
      case 'currency':
        return (
          <div className={fieldClassName}>
            <InputCurrency ref={ref} {...rest} />
          </div>
        )
      case 'number':
      case 'decimal':
        return (
          <div className={fieldClassName}>
            <InputNumber
              ref={ref}
              mask={mask}
              decimal={type === 'decimal' || fullAccuracy}
              {...rest}
            />
          </div>
        )
      case 'percentage':
      case 'percentage-hover':
        return (
          <div className={fieldClassName}>
            <InputPercentage
              ref={ref}
              mode={type === 'percentage-hover' ? 'hover' : undefined}
              fullAccuracy={fullAccuracy}
              {...rest}
            />
          </div>
        )
      default:
        return (
          <div className={fieldClassName}>
            <input
              ref={ref}
              {...rest}
              className={clsx(styles.input, rest.className)}
            />
          </div>
        )
    }
  }
)
IconInput.displayName = 'IconInput'

export default IconInput
