import clsx from 'clsx'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useLogoUrl } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'
import theme from 'styles/theme'
import { LogoFullImg } from './LogoFullImg'
import { LogoIconImg } from './LogoIconImg'
import styles from './styles.module.scss'

interface Props {
  color?: 'white' | 'blue'
  icon?: boolean
  wrapperClassName?: string
  logoClassName?: string
  onLoad?: () => void
  to: string
}

function Logo({
  color = 'white',
  icon,
  wrapperClassName,
  logoClassName,
  to,
  onLoad,
}: Props) {
  const { user } = useSession()
  const customLogoImage = user?.client?.image?.url
  const hexColor =
    color === 'white' ? theme.color.white100 : theme.color.blue200
  const { data: loadedCustomLogoImage, isPending } = useLogoUrl({
    enabled: !user,
  })
  const isWaitingForLogo = !user && isPending

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setTimeout(() => {
        onLoad && onLoad()
      }, 1)
    }
    if (customLogoImage) {
      img.src = customLogoImage
    }
    if (loadedCustomLogoImage) {
      img.src = loadedCustomLogoImage
    }
    if (!customLogoImage && !loadedCustomLogoImage && !isWaitingForLogo) {
      onLoad && onLoad()
    }
  }, [customLogoImage, loadedCustomLogoImage])

  if (isWaitingForLogo) {
    return <div className={clsx(styles.logoWrapper, wrapperClassName)} />
  }

  if (customLogoImage || loadedCustomLogoImage) {
    return (
      <NavLink to={to}>
        <div className={clsx(styles.logoWrapper, wrapperClassName)}>
          <img
            className={clsx(styles.custom, logoClassName)}
            src={customLogoImage || loadedCustomLogoImage}
          />
        </div>
      </NavLink>
    )
  }

  if (icon) {
    return (
      <NavLink to={to}>
        <div className={clsx(styles.logoWrapper, wrapperClassName)}>
          <LogoIconImg className={clsx(logoClassName)} />
        </div>
      </NavLink>
    )
  }

  return (
    <NavLink to={to}>
      <div className={clsx(styles.logoWrapper, wrapperClassName)}>
        <LogoFullImg
          className={clsx(logoClassName)}
          style={{ color: hexColor }}
        />
      </div>
    </NavLink>
  )
}

export default Logo
