import { useState } from 'react'
import {
  useAddEmailMutation,
  useSendEmailVerificationMutation,
  useUpdateEmailMutation,
  useDeleteEmailMutation,
} from 'admin/hooks/use-email'
import styles from 'admin/pages/Borrower/styles.module.scss'
import { Badge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Indicator } from 'components/Indicator'
import { LinkEmail } from 'components/LinkEmail'
import { ModalAddEmail } from 'components/Modal/AddEmail'
import { TextLink } from 'components/TextLink'
import { Email, EmailStatus, PersonType } from 'types/person'
import { Panel } from './index'

type Props = {
  id: string
  emails: Email[]
  personType?: 'borrower' | 'investor' | 'vendor'
  isInvited?: boolean
  type: PersonType
  readonlyEmails?: Email[]
}

function getStatusBadge(status: EmailStatus, isLinked?: boolean) {
  if (status === 'verified') {
    return (
      <div className={styles.emailStatus}>
        <Indicator color="green" /> {isLinked ? 'Linked via Team' : 'Verified'}
      </div>
    )
  }

  if (status === 'bounced') {
    return (
      <div className={styles.emailStatus}>
        <Indicator color="red" /> {isLinked ? 'Linked via Team' : 'Bounced'}
      </div>
    )
  }

  return (
    <div className={styles.emailStatus}>
      <Indicator color="yellow" /> {isLinked ? 'Linked via Team' : 'Unverified'}
    </div>
  )
}

function PanelEmail({
  id,
  type,
  emails,
  personType = 'borrower',
  isInvited = false,
  readonlyEmails,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { mutate: addEmail, isPending: isAdding } = useAddEmailMutation(
    personType,
    id
  )
  const { mutate: sendEmailVerification } =
    useSendEmailVerificationMutation(personType)
  const { mutate: updateEmail } = useUpdateEmailMutation(personType, id)
  const { mutate: deleteEmail } = useDeleteEmailMutation(personType, id)

  readonlyEmails?.sort((a, b) => {
    return a.email.localeCompare(b.email, undefined, { sensitivity: 'base' })
  })

  if (
    type === 'entity' &&
    emails.length === 0 &&
    readonlyEmails?.length === 0
  ) {
    return null
  }

  return (
    <Panel title="Email">
      <div className={styles.emails}>
        {emails.map((email) => (
          <div key={email.id} className={styles.email}>
            <div className={styles.emailWithStatus}>
              <LinkEmail email={email.email} />
              {getStatusBadge(email.status)}
              {email.primary && (
                <div className={styles.emailStatus}>
                  <Badge color="blue">Primary</Badge>
                </div>
              )}
            </div>
            <EllipsesActions>
              {!email.primary && (
                <EllipsesActions.Item
                  icon
                  disabled={
                    email.status !== 'verified' &&
                    !emails.every(({ status }) => status !== 'verified')
                  }
                  onSelect={() =>
                    updateEmail({
                      id,
                      emailId: email.id,
                      primary: true,
                    })
                  }
                >
                  <Icon name={IconName.email} />
                  Make Primary
                </EllipsesActions.Item>
              )}
              {email.status !== 'verified' && (
                <EllipsesActions.Item
                  icon
                  onSelect={() =>
                    sendEmailVerification({
                      id,
                      emailId: email.id,
                    })
                  }
                >
                  <Icon name={IconName.send} />
                  {email.verifyToken ? 'Resend' : 'Send'} Verification
                </EllipsesActions.Item>
              )}
              {!email.primary && (
                <EllipsesActions.Item
                  icon
                  onSelect={() => deleteEmail({ id, emailId: email.id })}
                  className="text-red-100"
                >
                  <Icon name={IconName.delete} />
                  Delete
                </EllipsesActions.Item>
              )}
            </EllipsesActions>
          </div>
        ))}
        {readonlyEmails?.map((email) => (
          <div key={email.email} className={styles.email}>
            <div className={styles.emailWithStatus}>
              {email.email}
              {getStatusBadge(email.status, true)}
            </div>
          </div>
        ))}
        {type === 'individual' && (
          <div className={styles.email}>
            <TextLink onClick={() => setIsModalVisible(true)}>
              <Icon name={IconName.plus} size="sm" /> Add Email
            </TextLink>
          </div>
        )}
      </div>
      {isModalVisible && (
        <ModalAddEmail
          saving={isAdding}
          isInvited={isInvited}
          onSubmit={(values) =>
            addEmail(values, {
              onSettled: () => {
                setIsModalVisible(false)
              },
            })
          }
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  )
}

export default PanelEmail
