import clsx from 'clsx'
import React, { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DropdownItem } from 'admin/components/TopMenu/DropdownItem'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { useMobile } from 'hooks/use-mobile'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

interface Props {
  children: ReactNode
  pathTo: any
  setIsMobileNavVisible: (visible: boolean) => void
}

const SideNavigation = ({ children, pathTo, setIsMobileNavVisible }: Props) => {
  const { user } = useSession()
  const { isTablet } = useMobile()
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <div
      className={clsx('no-print', styles.nav, {
        [styles.mobile]: isTablet,
      })}
    >
      <div className={styles.navContent}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className={styles.navHeader}
        >
          <div className={styles.dropdown}>
            <Dropdown
              open={menuOpen}
              onOpenChange={(nextOpen) => {
                setMenuOpen(nextOpen)
              }}
              trigger={
                <div
                  className={clsx(
                    'flex alignCenter gap-2 p-1 hover:bg-grey-100 hover:rounded cursor-pointer',
                    menuOpen && 'bg-grey-100 rounded'
                  )}
                >
                  <div className={styles.logoWrap}>
                    <Icon name={IconName.fundNavigation} size="md" />
                  </div>
                  <div className="grow w-28 text-ellipsis overflow-hidden whitespace-nowrap text-base font-bold -tracking-[.1px]">
                    {user?.client?.name}
                  </div>
                  <Icon name={IconName.dropdown} className="text-grey-600" />
                </div>
              }
              className="!w-80 !left-3 !z-[1000]"
            >
              <div className="p-1">
                <DropdownItem
                  itemName="Products"
                  iconName={IconName.productNavigation}
                  onSelect={() => {
                    navigate(pathTo('products'))
                    setMenuOpen(false)
                  }}
                />
                <DropdownItem
                  itemName="Automations"
                  iconName={IconName.automations}
                  onSelect={() => {
                    navigate(pathTo('settingsAutomations'))
                    setMenuOpen(false)
                  }}
                />
                <DropdownItem
                  itemName="Templates"
                  iconName={IconName.twoSheets}
                  onSelect={() => {
                    navigate(pathTo('settingsDocTemplates'))
                    setMenuOpen(false)
                  }}
                />
                <DropdownItem
                  itemName="Custom Data"
                  iconName={IconName.customField}
                  onSelect={() => {
                    navigate(pathTo('settingsCustomFields'))
                    setMenuOpen(false)
                  }}
                />
                <DropdownItem
                  itemName="Company Settings"
                  iconName={IconName.gearSettings}
                  onSelect={() => {
                    navigate(pathTo('settings'))
                    setMenuOpen(false)
                  }}
                />
              </div>
            </Dropdown>
          </div>
          {isTablet && (
            <Icon
              name={IconName.close}
              className={styles.closeIcon}
              size="lg"
              onClick={() => setIsMobileNavVisible(false)}
            />
          )}
        </Flex>
        <div className={styles.menus}>{children}</div>
      </div>
    </div>
  )
}

export default SideNavigation
