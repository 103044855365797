import { get, set } from 'lodash'
import { useState } from 'react'
import { InlineSummary } from 'admin/components/InlineEdit'
import { useBanking, useUpdateBanking } from 'admin/hooks/use-banking'
import { useUpdateCurrentClientSettings } from 'admin/hooks/use-users'
import { Grid } from 'components/Grid'
import { ModalBanking } from 'components/Modal/Banking'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { useSession } from 'hooks/use-session'

export const PanelBankingInfo = () => {
  const { user } = useSession()
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const { data: banking, isPending } = useBanking()
  const { mutate: update, isPending: saving } = useUpdateBanking()
  const { mutateAsync: updateClient } = useUpdateCurrentClientSettings()
  const clientId = get(user, 'client.id', '')
  const settings = get(user, 'client.settings', {
    rollupNachaPayoutsTransactions: false,
  })

  return (
    <Panel
      title="NACHA Settings"
      onEdit={() => setIsEditModalVisible(true)}
      className="max-w-panel"
      loading={isPending}
    >
      <Grid gap={24}>
        <Grid.Item xs={6}>
          <Summary name="Account Name">{banking?.accountName}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Account Number">{banking?.accountNumber}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Routing Number">{banking?.routingNumber}</Summary>
        </Grid.Item>

        <Grid.Item xs={6}>
          <Summary name="Account Type">{banking?.accountType}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Bank Name">{banking?.bankName}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Immediate Origin">{banking?.immediateOrigin}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Immediate Origin Name">
            {banking?.immediateOriginName}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Reference Code">{banking?.referenceCode}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Company Name">{banking?.companyName}</Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Standard Entry Class Code">
            {banking?.standardEntryClassCode}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Company Identification">
            {banking?.companyIdentification}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Company Entry Description">
            {banking?.companyEntryDescription}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={6}>
          <Summary name="Company Discretionary Data">
            {banking?.companyDiscretionaryData}
          </Summary>
        </Grid.Item>
      </Grid>

      <Grid className="mt-6 pt-6 border-0 border-t border-solid border-grey-75">
        <Grid.Item xs={6}>
          <InlineSummary
            type="option"
            name="Transaction Mode"
            value={
              settings.rollupNachaPayoutsTransactions
                ? 'Rollup Transactions'
                : 'Individual Transactions'
            }
            options={[
              { value: 'Rollup Transactions', label: 'Rollup Transactions' },
              {
                value: 'Individual Transactions',
                label: 'Individual Transactions',
              },
            ]}
            hideEmptyOption
            save={(value) => {
              const updatedSettings = {
                ...settings,
                rollupNachaPayoutsTransactions: value === 'Rollup Transactions',
              }
              set(user as any, 'client.settings', updatedSettings)
              return updateClient({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
      </Grid>
      {isEditModalVisible && (
        <ModalBanking
          banking={banking || null}
          saving={saving}
          onSave={(banking) =>
            update(banking, {
              onSuccess: () => {
                setIsEditModalVisible(false)
              },
            })
          }
          onCancel={() => setIsEditModalVisible(false)}
        />
      )}
    </Panel>
  )
}
