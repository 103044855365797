import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface SideNavigationLinkProps {
  to: string
  icon: IconName
  arrowIcon: IconName
  label: string
  onClick: () => void
  onOpenContacts: () => void
}

export const SideNavigationAccordionLink = ({
  to,
  icon,
  arrowIcon,
  label,
  onClick,
  onOpenContacts,
}: SideNavigationLinkProps) => {
  return (
    <NavLink
      className={({ isActive }) =>
        clsx(styles.link, 'relative p-0', {
          [styles.selected]: isActive,
        })
      }
      to={to}
    >
      <Flex
        onClick={onClick}
        alignItems="center"
        className="w-full px-2 py-1.5"
      >
        <Icon name={icon} size="md" />
        {label}
      </Flex>
      <div
        onClick={(e) => {
          e.preventDefault()
          onOpenContacts()
        }}
        className={clsx(
          styles.link,
          'absolute right-0 h-full m-0 rounded-l-none'
        )}
      >
        <Icon name={arrowIcon} size="md" className="!text-grey-600" />
      </div>
    </NavLink>
  )
}
