import { useState, useEffect } from 'react'
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom'
import { ImportEntity } from 'admin/components/ImportEntity'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddBorrower, useBorrowers } from 'admin/hooks/use-borrowers'
import {
  TableAll,
  TableCompanies,
  TablePeople,
} from 'admin/pages/Borrowers/Table'
import { pathTo } from 'admin/path-to'
import { Borrower } from 'admin/services/api/borrowers'
import { downloadBorrowers } from 'admin/services/csv/download-borrowers'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { BORROWER_IMPORT_BANNER } from 'constants/local-storage-keys'
import { usePagination } from 'hooks/use-pagination'
import { Filter } from 'types'
import { AlertBorrowers } from './AlertBorrowers'
import { EmptyBorrowers } from './EmptyBorrowers'

const getFilterByTab = (tab: 'all' | 'companies' | 'people') => {
  switch (tab) {
    case 'companies':
      return { type: ['entity'] }
    case 'people':
      return { type: ['individual'] }
    default:
      return {}
  }
}

function Borrowers() {
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const tab = (searchParams.get('tab') || 'all') as
    | 'all'
    | 'companies'
    | 'people'
  const {
    visibleItems,
    result,
    search,
    sort,
    isEmpty,
    setSearch,
    setPagination,
    setSort,
    resetPagination,
    updateItem,
  } = usePagination<Borrower>({
    property: 'borrowers',
    useData: (params) =>
      params.search
        ? useBorrowers({
            ...params,
            filter: {
              ...(params.filter || {}),
              ...getFilterByTab(tab),
            } as Filter,
            details: true,
          })
        : useBorrowers({
            ...params,
            details: true,
            filter: {
              isAccount: [true],
              ...getFilterByTab(tab),
            } as Filter,
          }),
  })

  const [adding, setAdding] = useState(false)
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(BORROWER_IMPORT_BANNER)
  )
  const navigate = useNavigate()
  const handleRowClick = (borrower?: Borrower) => {
    if (borrower?.id) {
      navigate(pathTo('borrower', borrower.id))
    }
  }
  const add = useAddBorrower()

  useEffect(() => {
    setLoading(true)
    resetPagination()
  }, [resetPagination, tab])

  useEffect(() => {
    if (!result.isFetching) {
      setLoading(false)
    }
  }, [result.isFetching])

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Borrowers" />
        {alertData && (
          <AlertBorrowers alertData={alertData} setAlertData={setAlertData} />
        )}
        {isEmpty ? (
          <EmptyBorrowers
            onClick={() => setAdding(true)}
            onImport={() => setAlertData(true)}
          />
        ) : (
          <Panel>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className="pb-4"
            >
              <Flex gap={8}>
                {[
                  { id: 'all', title: 'All' },
                  { id: 'companies', title: 'Companies' },
                  { id: 'people', title: 'People' },
                ].map(({ id, title }) => (
                  <Button
                    key={id}
                    active={tab === id}
                    variant="panel"
                    onClick={() =>
                      navigate({
                        pathname: pathTo('borrowers'),
                        search: createSearchParams({ tab: id }).toString(),
                      })
                    }
                  >
                    {title}
                  </Button>
                ))}
              </Flex>
              <div className="flex justify-center gap-4 sm:justify-end">
                <Search search={search} onSearch={setSearch} />
                <ImportEntity
                  entityType="borrower"
                  setAlertData={() => setAlertData(true)}
                />
                <Download
                  filename="borrowers"
                  download={() => downloadBorrowers(sort, search)}
                />
                <Button variant="primary" onClick={() => setAdding(true)}>
                  Add Borrower
                </Button>
              </div>
            </Flex>

            {tab === 'all' && (
              <TableAll
                key="all"
                data={loading ? [] : visibleItems}
                loading={loading}
                sort={sort}
                onClick={handleRowClick}
                onSort={setSort}
                onUpdateItem={updateItem}
              />
            )}
            {tab === 'companies' && (
              <TableCompanies
                key="companies"
                data={loading ? [] : visibleItems}
                loading={loading}
                sort={sort}
                onClick={handleRowClick}
                onSort={setSort}
                onUpdateItem={updateItem}
              />
            )}
            {tab === 'people' && (
              <TablePeople
                key="people"
                data={loading ? [] : visibleItems}
                loading={loading}
                sort={sort}
                onClick={handleRowClick}
                onSort={setSort}
                onUpdateItem={updateItem}
              />
            )}
            <LoadMore
              loading={loading}
              fetching={result.isFetching}
              count={visibleItems.length}
              meta={result.data?.meta}
              onLoadMore={setPagination}
            />
          </Panel>
        )}
      </Flex>

      {adding ? (
        <ModalAddPerson
          saving={add.isPending}
          include={['sendInvitation', 'creditScore']}
          onSave={(
            borrower: Omit<Borrower, 'id'> & { sendInvitation?: boolean }
          ) =>
            add.mutate(borrower, {
              onSuccess: ({ id }) => {
                navigate(pathTo('borrower', id))
              },
            })
          }
          onCancel={() => {
            setAdding(false)
          }}
        />
      ) : null}
    </MainContent>
  )
}

export { Borrowers }
