import { compact, last } from 'lodash'
import { useEffect, useState } from 'react'
import { SharedBadge } from 'admin/pages/Document/SharedBadge'
import { DocumentStatusBadge } from 'components/Badge'
import { Button } from 'components/Button'
import { ButtonGroup } from 'components/ButtonGroup'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header as TextHeader } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalRecallDocument } from 'components/Modal/RecallDocument'
import { useInvalidateDocument } from 'hooks/use-document'
import { declineDocument } from 'services/api/document'
import { DocumentVersion, LoanDocument } from 'types'
import { friendlyDate } from 'utils/date'
import { friendlyMime, isPrintable } from 'utils/file'
import { message } from 'utils/message'
import { HeaderActions } from './HeaderActions'
import { StatusSelect } from './StatusSelect'
import styles from './styles.module.scss'

interface Props {
  document: LoanDocument
  isLocked: boolean
  version?: DocumentVersion
  sharePersons?: { id: string; name: string; typeOfPerson: string }[]
  onBack: () => void
  onEdit: (document: Partial<LoanDocument>) => void
  onRename: () => void
  onShare?: () => void
  onUpload: () => void
  onDownload: () => void
  onPrint: () => void
  onComment: () => void
  onDelete: () => void
  onRequest?: () => void
  onSignature: () => void
}

export function Header({
  document,
  isLocked,
  version,
  sharePersons,
  onBack,
  onEdit,
  onRename,
  onShare,
  onUpload,
  onDownload,
  onComment,
  onPrint,
  onDelete,
  onRequest,
  onSignature,
}: Props) {
  const [status, setStatus] = useState(document.status)
  const [showRecall, setShowRecall] = useState(false)
  const invalidateDocument = useInvalidateDocument(document.id)
  const statuses: LoanDocument['status'][] = compact([
    'pending',
    onRequest ? 'requested' : null,
    'in_review',
    'accepted',
    'rejected',
  ])
  const handleStatusChange = (nextStatus: LoanDocument['status']) => {
    setStatus(nextStatus)
    onEdit({ status: nextStatus })
  }
  useEffect(() => {
    setStatus(document.status)
  }, [document.status])

  const isSignable =
    !!document.esignatureToken &&
    (!version || version.id === last(document.versions || [])?.id) &&
    document.esignature?.status !== 'In Progress'

  return (
    <div className={styles.header}>
      <Flex stack className="flex-grow">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <div className={styles.details}>
            <Button variant="ghost" icon onClick={onBack}>
              <Icon name={IconName.arrowLeftLong} />
            </Button>
            <div className={styles.fileInfo}>
              <Flex alignItems="center">
                <TextHeader variant="h3">{document.name}</TextHeader>
                <div className="text-grey-600">
                  {friendlyMime(document.type)}
                </div>
                {document.isShared && (
                  <SharedBadge sharePersons={sharePersons} />
                )}
              </Flex>
            </div>
          </div>
          <Flex alignItems="center" flexWrap="wrap">
            {document.uploadedDate && (
              <div className={styles.metadata}>
                <div>Last Updated {friendlyDate(document.uploadedDate)}</div>
              </div>
            )}

            <div className={styles.actions}>
              {isLocked ? (
                <DocumentStatusBadge
                  className={styles.btnStatus}
                  status={status}
                />
              ) : (
                <>
                  <StatusSelect
                    statuses={statuses}
                    status={status}
                    isSignature={!!document.esignatureToken}
                    onSelect={handleStatusChange}
                  />
                  {document.esignature?.status === 'In Progress' && (
                    <Button
                      variant="ghost"
                      iconLeft={
                        <Icon
                          name={IconName.reverse}
                          className="text-red-50"
                        ></Icon>
                      }
                      className="ml-[-10px]"
                      onClick={() => setShowRecall(true)}
                    >
                      Recall Document
                    </Button>
                  )}
                </>
              )}

              <ButtonGroup>
                <Button variant="secondary" onClick={onDownload}>
                  <Icon name={IconName.download} />
                  Download
                </Button>
                <EllipsesActions
                  trigger={
                    <Button variant="secondary" icon>
                      <Icon name={IconName.arrowDownFilled} />
                    </Button>
                  }
                >
                  <EllipsesActions.Item icon onSelect={onUpload}>
                    <Icon name={IconName.upload} />
                    Upload
                  </EllipsesActions.Item>
                </EllipsesActions>
              </ButtonGroup>
            </div>
          </Flex>
        </Flex>
        <HeaderActions
          isLocked={isLocked}
          isPrintable={isPrintable(version?.type)}
          isShared={document.isShared}
          isSignable={isSignable}
          hasVersion={!!version}
          onRename={onRename}
          onPrint={onPrint}
          onComment={onComment}
          onRequest={onRequest}
          onShare={onShare}
          onSignature={onSignature}
          onDelete={onDelete}
        />
      </Flex>
      {showRecall && (
        <ModalRecallDocument
          saving={false}
          onRecall={async ({ message: reason }) => {
            await declineDocument(document?.id as string, reason)
            message.success('Recalled the document')
            setShowRecall(false)
            invalidateDocument()
          }}
          onCancel={() => {
            setShowRecall(false)
          }}
        />
      )}
    </div>
  )
}
