import { isString } from 'lodash'
import { useCallback, useState } from 'react'
import { SelectVendor } from 'admin/components/SelectVendor'
import { useAddVendor } from 'admin/hooks/use-vendors'
import { ModalAddVendor } from 'admin/pages/Vendors/ModalAddVendor'
import { Vendor } from 'admin/services/api/vendors'
import { Button } from 'components/Button'
import { DatePicker } from 'components/DatePicker'
import { Flex } from 'components/Flex'
import { InputCurrency } from 'components/InputCurrency'
import { Modal } from 'components/Modal'
import { NoteField } from 'components/NoteField'
import { Loan } from 'types'

interface Props {
  loan: Loan
  saving: boolean
  onSave: (values: {
    dateOfSale: string
    soldTo: string | null
    realizedAmount: string
    note: string
  }) => void
  onCancel: () => void
}

function ModalMarkAsSold({ saving, onSave, onCancel }: Props) {
  const [addingVendorName, setAddingVendorName] = useState<string | false>(
    false
  )
  const [dateOfSale, setDateOfSale] = useState<string>('')
  const [soldTo, setSoldTo] = useState<string | null>(null)
  const [realizedAmount, setRealizedAmount] = useState<string>('')
  const [note, setNote] = useState<string>('')

  const { mutate: addVendor, isPending: isVendorAdding } = useAddVendor()

  const handleSave = useCallback(() => {
    onSave({
      dateOfSale,
      soldTo,
      realizedAmount,
      note,
    })
  }, [dateOfSale, soldTo, realizedAmount, note])

  return (
    <>
      <Modal title="Mark Loan as Sold" onClose={onCancel} className="w-modal">
        <Flex stack gap={16}>
          <Flex stack gap={6}>
            <div>Realized Gain/Loss on Sale</div>
            <DatePicker value={dateOfSale} onChange={setDateOfSale} />
          </Flex>
          <Flex stack gap={6}>
            <div>Realized Gain/Loss on Sale</div>
            <InputCurrency
              value={realizedAmount}
              onChange={(e) => setRealizedAmount(e.target.value)}
            />
          </Flex>
          <Flex stack gap={6}>
            <div>Sold to</div>
            <SelectVendor
              value={soldTo}
              onSelect={setSoldTo}
              onCreate={(text) => setAddingVendorName(text)}
            />
          </Flex>
          <NoteField value={note} onChange={setNote} />

          <Flex justifyContent="flex-end" gap={10}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={saving} onClick={handleSave}>
              Mark as Sold
            </Button>
          </Flex>
        </Flex>
      </Modal>
      {isString(addingVendorName) && (
        <ModalAddVendor
          saving={isVendorAdding}
          onSave={(vendor) =>
            addVendor(vendor as Omit<Vendor, 'id'>, {
              onSuccess: ({ id }) => {
                setSoldTo(id)
                setAddingVendorName(false)
              },
            })
          }
          onCancel={() => {
            setAddingVendorName(false)
          }}
        />
      )}
    </>
  )
}

export { ModalMarkAsSold }
