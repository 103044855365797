import { useNavigate } from 'react-router-dom'
import { useLoanType } from 'admin/hooks/use-loan-type'
import {
  useDeleteLoan,
  useUpdateLoan,
  useDuplicateLoan,
  useShareLoan,
} from 'admin/hooks/use-loans'
import { ModalMarkAsSold } from 'admin/pages/Loan/ModalMarkAsSold'
import { ModalShareLoan } from 'admin/pages/Loan/ModalShareLoan'
import { pathTo, pathToLoan } from 'admin/path-to'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalDelete } from 'components/Modal/Delete'
import { Loan } from 'types'
import { message } from 'utils/message'

interface Props {
  action: string | null
  setAction: (action: string | null) => void
  loan: Loan
}

function TabGeneralActions({ action, setAction, loan }: Props) {
  const { isOrigination } = useLoanType()

  const navigate = useNavigate()
  const { mutate: remove, isPending: removing } = useDeleteLoan()
  const { mutate: update, isPending: updating } = useUpdateLoan({ id: loan.id })
  const { mutate: share, isPending: sharing } = useShareLoan(loan.id)
  const { mutate: duplicate, isPending: duplicating } = useDuplicateLoan({
    id: loan.id,
    onSuccess: (newLoan: Loan) => {
      navigate(pathToLoan(newLoan))
    },
  })

  return (
    <>
      {action === 'delete' && (
        <ModalDelete
          resource="loan"
          loading={removing}
          onDelete={() =>
            remove(loan.id, {
              onSuccess: () => {
                setAction(null)
                navigate(pathTo(isOrigination ? 'loans' : 'servicing'))
              },
            })
          }
          onCancel={() => setAction(null)}
        />
      )}
      {action === 'servicing' && (
        <ModalConfirm
          title="Move to servicing"
          text="Are you sure you want to move this loan to servicing?"
          loading={updating}
          onConfirm={() =>
            update(
              { status: 'servicing' },
              {
                onSuccess: (loan) => {
                  setAction(null)
                  navigate(pathToLoan(loan), { replace: true })
                },
              }
            )
          }
          onCancel={() => setAction(null)}
        />
      )}
      {action === 'archive' && (
        <ModalConfirm
          title="Archive"
          text="Are you sure you want to archive this loan?"
          loading={updating}
          onConfirm={() =>
            update(
              { status: 'archived' },
              {
                onSuccess: () => {
                  setAction(null)
                  navigate(pathTo(isOrigination ? 'loans' : 'servicing'))
                },
              }
            )
          }
          onCancel={() => setAction(null)}
        />
      )}
      {action === 'restore' && (
        <ModalConfirm
          title="Restore"
          text="Are you sure you want to restore this loan?"
          loading={updating}
          onConfirm={() =>
            update(
              { status: 'closed' },
              {
                onSuccess: () => {
                  setAction(null)
                },
              }
            )
          }
          onCancel={() => setAction(null)}
        />
      )}
      {action === 'rollback' && (
        <ModalConfirm
          title="Rollback from servicing"
          text="Are you sure you want to rollback this loan from servicing?"
          loading={updating}
          onConfirm={() =>
            update(
              { status: 'closed' },
              {
                onSuccess: (loan) => {
                  setAction(null)
                  navigate(pathToLoan(loan), { replace: true })
                },
              }
            )
          }
          onCancel={() => setAction(null)}
        />
      )}
      {action === 'duplicate' && (
        <ModalConfirm
          title="Duplicate"
          text="Are you sure you want to create a copy of this loan? All payments and funding will copied to the new loan."
          loading={duplicating}
          onConfirm={() =>
            duplicate(undefined, {
              onSuccess: () => {
                setAction(null)
              },
            })
          }
          onCancel={() => setAction(null)}
        />
      )}
      {action === 'share' && (
        <ModalShareLoan
          loan={loan}
          saving={sharing}
          onSave={({ message: shareMessage }) =>
            share(
              { clientId: loan.product.clientId, message: shareMessage },
              {
                onSuccess: () => {
                  setAction(null)
                  message.success('Sent request to share the loan')
                },
              }
            )
          }
          onCancel={() => setAction(null)}
        />
      )}
      {action === 'sold' && (
        <ModalMarkAsSold
          loan={loan}
          saving={updating}
          onSave={(values) =>
            update(
              { status: 'sold', meta: { ...values } },
              {
                onSuccess: () => {
                  setAction(null)
                  message.success('Loan marked as sold')
                },
              }
            )
          }
          onCancel={() => setAction(null)}
        />
      )}
    </>
  )
}

export { TabGeneralActions }
