import clsx from 'clsx'
import { ReactNode, useEffect, useRef } from 'react'
import { useModal } from 'hooks/use-modal'
import { Icon, IconName } from '../Icon'
import Portal from './Portal'
import Progress, { Step } from './Progress'
import styles from './styles.module.scss'

interface ModalProps {
  children: ReactNode
  onClose: () => void
  title?: ReactNode
  className?: string
  asChild?: boolean
  loading?: boolean
  closeOnClickOutside?: boolean
  crossClose?: boolean
  step?: Step
  focusFirstInput?: boolean
}

function Modal({
  children,
  title,
  className,
  asChild,
  loading,
  onClose,
  closeOnClickOutside,
  crossClose,
  step,
  focusFirstInput = true,
}: ModalProps) {
  const overlay = useRef<HTMLInputElement>(null)
  const modal = useRef<HTMLInputElement>(null)
  useModal({ onClose, overlay, closeOnClickOutside })

  // focus first input in the modal
  useEffect(() => {
    const { current: modalEl } = modal
    if (modalEl && !loading && focusFirstInput) {
      const inputEl = modalEl.querySelector(
        'input:not([placeholder="MM/DD/YYYY"]), textarea'
      ) as HTMLInputElement
      setTimeout(() => inputEl?.focus(), 1)
    }
  }, [focusFirstInput, loading])

  return (
    <Portal className={styles.portal}>
      <div
        ref={overlay}
        data-esc-closable={true}
        data-overlay={true}
        className={styles.overlay}
      />
      {loading ? (
        <div className={styles.loader}>
          <Icon name={IconName.loaderSpinner} size="xl" className="spinner" />
        </div>
      ) : (
        <div ref={modal} className={clsx(className, styles.container)}>
          {step && <Progress step={step} />}
          {title && (
            <div className={styles.headerLine}>
              <div className="text-4xl font-bold">{title}</div>
              {crossClose ? (
                <Icon
                  name={IconName.close}
                  onClick={onClose}
                  size="lg"
                  className={styles.close}
                />
              ) : (
                <div />
              )}
            </div>
          )}
          {asChild ? (
            children
          ) : (
            <div className={styles.content}>{children}</div>
          )}
        </div>
      )}
    </Portal>
  )
}

export default Modal
