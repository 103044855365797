import { request } from 'services/request'
import { Person, Filter, Meta, Pagination, Sort } from 'types'

interface Props {
  search?: string
  filter?: Filter
  page?: Pagination
  sort?: Sort
}

const getPersons = async ({
  ...params
}: Props): Promise<{ people: Person[]; meta: Meta }> => {
  const {
    data: { people, pagination },
  } = await request.get('/person', { params: { ...params, details: true } })
  return { people, meta: pagination }
}

export { getPersons }
